<template>
  <div class="page">
    <Panel lineColor="var(--primary-color)">
      <h4 class="text-start">Configurações gerais</h4>
      <LineDivider></LineDivider>
      <v-row no-gutters>
        <v-col
          v-for="tag in form.tags"
          :key="tag.id"
          cols="12"
          md="4"
          v-show="
            !tag.onlyAdmin &&
              !tag.onlyAdvancedMaintenance &&
              (moduloFornecedorAtivo(tag.modulos) ||
                moduloFornecedorInativo(tag.inativeModulos))
          "
        >
          <FormCheckbox
            class="ma-0 pa-0"
            v-model="tag.active"
            :label="tag.description"
            :val="tag.active"
          />
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosFull)"
    >
      <h4 class="text-start">Dados básicos da empresa</h4>
      <LineDivider></LineDivider>
      <FormField
        v-model="fornecedor.nome"
        label="Razão Social"
        readonly
      ></FormField>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="
        moduloFornecedorAtivo(modulosCommerceOrcamento) && finishOrderManually
      "
    >
      <h4 class="text-start">Status após finalização manual</h4>
      <LineDivider></LineDivider>
      <FormSelect
        label="Status após finalização"
        v-model="form.statusAposFinalizacao"
        :options="arrayStatusAposSeparacao"
      />
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerceOrcamento)"
    >
      <h4 class="text-start">Configurações da loja</h4>
      <LineDivider></LineDivider>
      <FormSelect
        label="Fuso horário"
        v-model="form.fusoHorario"
        :options="arrayFusoHorario"
      />
      <FormSelect
        label="Ordenação padrão na busca de produtos"
        v-model="pesquisa.ordenacaoSelecionada"
        :options="pesquisa.ordernacaoDePesquisa"
      />
      <v-row no-gutters>
        <v-col cols="auto">
          <FormSwitch
            v-model="pesquisa.ocultarOrdenacaoDePesquisa"
            label="Ocultar ordenação para o cliente"
            :disabled="pesquisa.disableCheckBox"
          />
        </v-col>
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="ml-3 mt-3"
                >far fa-question-circle</v-icon
              >
            </template>
            Quando marcada, a opção de ordernar a busca não estará visível para
            os clientes, respeitando apenas a ordenação padrão.
          </v-tooltip>
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerceOrcamento)"
    >
      <h4 class="text-start">Horários de atendimento</h4>
      <LineDivider></LineDivider>
      <v-row no-gutters>
        <v-col cols="auto">
          <v-card elevation="1">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="py-4 text-center" style="font-size: 1em;">
                    Status
                  </th>
                  <th class="py-4 text-center" style="font-size: 1em;">
                    Início
                  </th>
                  <th class="py-4 text-center" style="font-size: 1em;">
                    Fim
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="w in weekdays" :key="w.day">
                  <td>
                    <FormSwitch
                      class="mb-n3"
                      v-model="w.active"
                      :label="w.day"
                    />
                  </td>
                  <td>
                    <FormSelect
                      class="mb-n7"
                      v-model="w.iHour"
                      :options="hours"
                    />
                  </td>
                  <td>
                    <FormSelect
                      class="mb-n7"
                      v-model="w.fHour"
                      :options="hours"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerceOrcamentoPainel)"
    >
      <h4 class="text-start">Configuração de cliente</h4>
      <LineDivider></LineDivider>
      <FormField
        v-model="configCliente.limite_credito_disponivel"
        typeField="number"
        label="Limite disponível padrão"
        help="Configure nesse campo que limite será atribuido para clientes que se cadastrarem por meio do seu site ou app. Caso tenha habilitado o bloqueio de pedidos por limite, essa configuração impedirá o cliente de realizar compras acima desse valor em sua primeira compra, até que seja corrigido no seu cadastro ou no ERP integrado, caso utilize."
      />
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerceOrcamentoPainel)"
    >
      <h4 class="text-start">Configuração de pedido</h4>
      <LineDivider></LineDivider>
      <FormField
        v-if="moduloFornecedorAtivo(modulosCommerceOrcamento)"
        v-model="configPedido.maxPedidosClientes"
        typeField="number"
        label="Número máximo de pedidos feitos por cada cliente diariamente"
      />
      <FormField
        v-model="configPedido.maxPagamentoPedido"
        typeField="number"
        label="Número máximo tentativas de pagamentos por pedido"
      />
      <v-row no-gutters v-if="moduloFornecedorAtivo(modulosCommerceOrcamento)">
        <v-col cols="auto">
          <h4>Criar pedido em modo orçamento</h4>
        </v-col>
        <v-col class="text-start">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="ml-3 mt-n3"
                >far fa-question-circle</v-icon
              >
            </template>
            Quando essa configuração for ativada, os pedidos com o total maior
            do que o valor cadastrado serão criados como orçamento precificado.
            Será necessário submeter esse orçamento para que se torne um pedido
            na tela de atendimento do pedido.
          </v-tooltip>
        </v-col>
        <v-col cols="12">
          <FormRadioGroup
            v-model="usarConfig.minPedidoOrcamento"
            inline
            :radios="arrayPedidoOrcamento"
          />
        </v-col>
        <v-col cols="12" v-if="usarConfig.minPedidoOrcamento">
          <FormField
            v-model="configPedido.minPedidoOrcamento"
            typeField="number"
            label="Valor mínimo para pedidos serem criados como orçamento"
          />
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerceVendaMais)"
    >
      <h4 class="text-start">Configuração de preço</h4>
      <LineDivider></LineDivider>
      <v-row>
        <v-col cols="12" md="6">
          <v-row no-gutters>
            <v-col>
              <FormSelect
                label="Quantidade de casas decimais"
                v-model="configPreco.decimais"
                :options="arrayDecimais"
              />
            </v-col>
            <v-col class="text-start" cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="ml-3 mt-2"
                    >far fa-question-circle</v-icon
                  >
                </template>
                O número de casas decimais que será utilizada para os preços na
                plataforma.<br />
                A exibição desses preços no aplicativo e no site ainda podem ser
                ajustados na tela de tema.
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <FormSelect
                label="Forma de arredondamento"
                v-model="configPreco.arredondamento"
                :options="arrayArredondamento"
              />
            </v-col>
            <v-col class="text-start" cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="ml-3 mt-2"
                    >far fa-question-circle</v-icon
                  >
                </template>
                Formula de arredondamento de como o preço será tratado na
                plataforma.
              </v-tooltip>
            </v-col>
            <v-col cols="12">
              <a
                href="https://atendimento.lifeapps.com.br/kb/article/380382/como-parametrizar-as-configuracoes-de-precos-e-arredondamento"
                target="_new"
              >
                Ajuda com a configuração de preço
              </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" style="border-left: 1px solid #CCC;">
          <v-card flat>
            <v-card-title>Pré-visualização</v-card-title>
            <v-divider />
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  v-for="i in 4"
                  :key="i"
                  class="text-start"
                  cols="12"
                  md="6"
                >
                  <p v-if="previewPrecoArredondado(i)">
                    <span class="font-weight-bold">Preço original:</span>
                    <span class="ml-3">{{ precoPreview[i] }}</span>
                  </p>
                  <p v-if="previewPrecoArredondado(i)">
                    <span class="font-weight-bold">Preço arredondado:</span>
                    <span class="ml-3">{{
                      previewPrecoArredondado(i).value
                    }}</span>
                    <v-icon
                      class="ml-3 red--text mt-n1"
                      size="15"
                      v-if="previewPrecoArredondado(i).isLower"
                    >
                      fas fa-arrow-down
                    </v-icon>
                    <v-icon
                      class="ml-3 green--text mt-n1"
                      size="15"
                      v-if="previewPrecoArredondado(i).isGreater"
                    >
                      fas fa-arrow-up
                    </v-icon>
                  </p>
                  <v-divider class="mb-5" />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="text-start">
                  <p>
                    <span class="font-weight-bold">Campo de teste</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="ml-3"
                          >far fa-question-circle</v-icon
                        >
                      </template>
                      Utilize o campo para testar algum valor e conferir a
                      configuração selecionada.
                    </v-tooltip>
                  </p>
                </v-col>
                <v-col class="text-start">
                  <FormField
                    v-model="precoPreview[4]"
                    typeField="number"
                    label="Preço"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerceOrcamento)"
    >
      <h4 class="text-start">
        Configuração da embalagem de presente de produto
      </h4>
      <LineDivider></LineDivider>
      <FormField
        label="Preço da embalagem de produto"
        v-model="configEmbalagem.precoEmbalagem"
        typeField="number"
        help="Configure nesse campo qual será o valor da embalagem de presente. Lembrando que você também poderá especificar o valor de um produto especifico acessando o cadastro de produto."
      />
      <a
        href="https://atendimento.lifeapps.com.br/kb/article/380377/como-habilitar-embalagem-de-presente-nos-produtos?ticketId=&q=ajuda%20com%20a%20configura%C3%A7%C3%A3o%20de%20embalagem"
        target="_new"
      >
        Ajuda com a configuração de embalagem
      </a>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerceOrcamento)"
    >
      <h4 class="text-start">Configuração de pesquisa de endereço</h4>
      <LineDivider></LineDivider>
      <v-row no-gutters>
        <FormSelect
          label="Elementos obrigatórios na pesquisa de endereço"
          v-model="configPesquisaEndereco.elementosObrigatorios"
          :options="arrayElementosObrigatorios"
        />
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="ml-3 mt-n7">far fa-question-circle</v-icon>
          </template>
          Determina quais elementos são obrigatórias para o cliente selecionar.
        </v-tooltip>
      </v-row>
      <a
        href="https://atendimento.lifeapps.com.br/kb/article/380380/como-parametrizar-as-configuracoes-de-pesquisa-de-endereco?ticketId=&q=configura%C3%A7%C3%A3o%20de%20pesquisa%20de%20endere%C3%A7o"
        target="_new"
      >
        Ajuda com a configuração de pesquisa de endereço
      </a>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerceOrcamentoPainelPortifolio)"
    >
      <h4 class="text-start">Domínio do E-Commerce</h4>
      <LineDivider></LineDivider>
      <v-row no-gutters>
        <v-col cols="12">
          <FormField
            label="Domínio principal"
            v-model="dominio"
            placeholder="Domínio principal do E-Commerce"
          />
          <DomainMessage class="mt-n3 mb-7" :dominio="dominio" v-if="dominio" />
          <v-row no-gutters v-for="(_dominio, i) in dominioAdicionais" :key="i">
            <v-col>
              <FormField
                label="Dominio adicional"
                v-model="dominioAdicionais[i]"
                placeholder="Domínio adicional do E-Commerce"
              />
            </v-col>
            <v-col cols="auto" class="ml-3">
              <FormButton
                type="error"
                text="Remover"
                @click="removerDominio(i)"
              />
            </v-col>
            <v-col cols="12">
              <DomainMessage
                class="mt-n3 mb-7"
                :dominio="dominioAdicionais[i]"
                v-if="dominioAdicionais[i]"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-start mb-5">
          <FormButton
            type="primario"
            text="Domínio adicional"
            @click="addDominio()"
          />
        </v-col>
        <v-col cols="12" class="text-start">
          Caso deseje configurar seu domínio próprio para que aponte para o
          e-commerce, configure entradas de DNS com os seguintes valores:
        </v-col>
        <v-col cols="12">
          <v-list>
            <v-list-item-group>
              <v-list-item>
                <v-list-item-content>
                  <span class="font-weight-bold d-flex">Tipo A:</span>
                  {{ dnsHelp.tipoA }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <span class="font-weight-bold d-flex">Tipo CNAME:</span>
                  {{ dnsHelp.tipoCname }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
      <a
        href="https://atendimento.lifeapps.com.br/kb/article/380384/como-configurar-o-dominio-da-sua-empresa-para-a-plataforma-de-e-?ticketId=&q=como%20configurar%20o%20dominio%20da%20sua%20empresa%20para%20a%20plataforma"
        target="_new"
      >
        Ajuda com a configuração de domínio
      </a>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerceOrcamentoPainelPortifolio)"
    >
      <h4 class="text-start">
        SEO (Search Engine Optimization)
      </h4>
      <LineDivider></LineDivider>
      <FormField
        label="Identificador da loja"
        v-model="seo.slug"
        @change="normalizeSlug()"
        counter
        maxLength="100"
        help="Nome que será usado na URL do site via superon.app/identificador."
      />
      <FormField
        label="Descrição curta sobre o site"
        v-model="seo.description"
        counter
        maxLength="160"
        help="Máximo 160 caracteres sobre o site, ela é utilizada por ferramentas de busca online."
      />
      <FormField
        label="Palavras chaves separadas por vírgula"
        v-model="seo.keywords"
        counter
        maxLength="160"
        help="Máximo 160 caracteres. Separadas por vírgula. Estas palavras são utilizadas pelas ferramentas de busca online."
      />
      <v-row no-gutters>
        <v-col cols="auto">
          <h4>Sitemap</h4>
        </v-col>
        <v-col cols="auto">
          <a class="mx-3" :href="urlSitemap" target="_blank">{{
            urlSitemap
          }}</a>
        </v-col>
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="mx-3">far fa-question-circle</v-icon>
            </template>
            Use este endereço para baixar o sitemap.
          </v-tooltip>
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosFull)"
    >
      <h4 class="text-start">
        Endereço da loja
      </h4>
      <LineDivider></LineDivider>
      <FormField label="Rua" v-model="endereco.rua" />
      <FormField label="Número" typeField="number" v-model="endereco.numero" />
      <FormField label="Complemento" v-model="endereco.complemento" />
      <FormField label="Bairro" v-model="endereco.bairro" />
      <FormField label="Cidade" v-model="endereco.cidade" />
      <FormSelect
        label="Estado"
        v-model="endereco.estado"
        :options="arrayEstados"
      />
      <FormField label="CEP" v-model="endereco.cep" />
      <FormField
        label="Resumo do endereço"
        v-model="endereco.descricao_endereco_loja"
      />
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerceOrcamento)"
    >
      <h4 class="text-start">Mapa da área de atuação</h4>
      <LineDivider></LineDivider>
      <ServiceAreaMap
        @load="onLoadMap"
        @receive-coords="onReceiveCoords"
        ref="areaMap"
      />
    </Panel>
    <v-row>
      <v-col class="text-center text-md-end my-3">
        <FormButton
          :loading="isLoading"
          type="primario"
          @click="onSubmit()"
          text="Salvar"
        />
      </v-col>
    </v-row>
    <DeploymentMessage :show="showWizard" :step="stepWizard" />
  </div>
</template>
<script>
import Panel from '@/components/Panel'
import ServiceAreaMap from '@/components/ServiceAreaMap'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import FormSelect from '@/components/form/FormSelect'
import FormSwitch from '@/components/form/FormSwitch'
import FormRadioGroup from '@/components/form/FormRadioGroup'
import LineDivider from '@/components/LineDivider'
import DomainMessage from '@/components/DomainMessage'
import DeploymentMessage from '@/components/DeploymentMessage'
import * as BigNumber from 'bignumber.js'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import {
  PARAMETRO_LOAD_TAGS,
  ORDENACAO_PESQUISA,
  FUSO_HORARIO,
  ORDER_STATUS,
  ORDER_STATUS_LABEL,
  ESTADOS,
  FORMAS_ARREDONDAMENTO,
  DECIMAIS,
  SEMANAS,
  ELEMENTOS_OBRIGATORIOS,
  PARAMETRO_LOAD,
  PARAMETRO_UPDATE_AGENDA,
  PARAMETRO_UPDATE_DATA
} from '@/store/actions/parametros'
import { FORNECEDOR_LOAD } from '@/store/actions/fornecedor'
import { ADMIN_GET_PASSOS_IMPLANTACAO } from '@/store/actions/admin'
import * as _ from 'lodash'
import StringUtils from '../../../utils/stringUtils'
//import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Parametros',
  components: {
    Panel,
    FormCheckbox,
    FormField,
    FormButton,
    FormSelect,
    LineDivider,
    FormSwitch,
    FormRadioGroup,
    DeploymentMessage,
    DomainMessage,
    ServiceAreaMap
  },
  data: () => ({
    isLoading: false,
    form: {
      tags: []
    },
    fornecedor: {},
    coordenadasLoja: {},
    pesquisa: {
      ordernacaoDePesquisa: Object.entries(
        ORDENACAO_PESQUISA
      ).map(([text, value]) => ({ text, value })),
      ordenacaoSelecionada: null,
      ocultarOrdenacaoDePesquisa: false,
      disableCheckBox: false
    },
    configPedido: {
      maxPedidosClientes: 99999,
      minPedidoOrcamento: 99999999,
      maxPagamentoPedido: 25
    },
    usarConfig: {
      minPedidoOrcamento: false
    },
    configCliente: {
      limite_credito_disponivel: 0
    },
    configPreco: {
      decimais: '6',
      arredondamento: 'ROUND_FLOOR'
    },
    configEmbalagem: { precoEmbalagem: null },
    configPesquisaEndereco: { elementosObrigatorios: ['cidade'] },
    precoPreview: {
      1: 2.6956,
      2: 2.6955,
      3: 2.6935548,
      4: 2.6934548
    },
    arrayStatusAposSeparacao: [
      {
        value: ORDER_STATUS.EMENTREGA,
        text: ORDER_STATUS_LABEL.EMENTREGA
      },
      {
        value: ORDER_STATUS.PAGO,
        text: ORDER_STATUS_LABEL.PAGO
      }
    ],
    arrayDecimais: DECIMAIS,
    arrayArredondamento: FORMAS_ARREDONDAMENTO,
    arrayElementosObrigatorios: ELEMENTOS_OBRIGATORIOS,
    weekdays: SEMANAS,
    arrayFusoHorario: FUSO_HORARIO.map(fuso => ({ text: fuso, value: fuso })),
    arrayPedidoOrcamento: [
      { label: 'Usar', value: true },
      { label: 'Não usar', value: false }
    ],
    arrayEstados: ESTADOS,
    dominioAdicionais: [],
    dominio: '',
    oldDomains: [],
    seo: {
      slug: null,
      description: null,
      keywords: null
    },
    endereco: {
      rua: '',
      complemento: '',
      cep: '',
      numero: '',
      cidade: '',
      estado: '',
      bairro: '',
      descricao_endereco_loja: ''
    },
    wizardPath: 'configuracao-loja',
    showWizard: '',
    stepWizard: {},
    modulosFull: [ModulesEnum.TODOS],
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ],
    modulosCommerceOrcamento: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ],
    modulosCommerceVendaMais: [
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ],
    modulosCommerceOrcamentoPainel: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP,
      ModulesEnum.PAINEL_CLIENTE
    ],
    modulosCommerceOrcamentoPainelPortifolio: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP,
      ModulesEnum.PAINEL_CLIENTE,
      ModulesEnum.PORTFOLIO_WEB
    ]
  }),
  validations: {
    form: {}
  },
  computed: {
    ...mapGetters([
      'getFornecedor',
      'moduloFornecedorAtivo',
      'moduloFornecedorInativo',
      'getFornecedorTag',
      'getFornecedorId'
    ]),
    finishOrderManually() {
      return this.getFornecedorTag['finish-order-manually']
    },
    emailInvalid() {
      return !this.$v.form.email.email
        ? 'Email é inválido'
        : !this.$v.form.email.required
        ? 'Campo obrigatório'
        : ''
    },
    hours() {
      let hours = []
      for (var i = 0; i < 24; i++) {
        var pad = '00'
        var ans =
          pad.substring(0, pad.length - i.toString().length) + i.toString()
        hours.push(`${ans}:00`)
        hours.push(`${ans}:30`)
      }
      return hours.map(h => ({ text: h, value: h }))
    },
    dnsHelp() {
      let tipoA = _.get(this.fornecedor, 'configsServer.help.dns.A', '--')
      let tipoCname = _.get(
        this.fornecedor,
        'configsServer.help.dns.CNAME',
        '--'
      )
      return {
        tipoA: tipoA,
        tipoCname: tipoCname
      }
    },
    urlSitemap() {
      return `http://${this.dominio}/sitemap.${this.seo.slug ||
        this.getFornecedorId}.xml`
    }
  },
  watch: {
    'pesquisa.ordenacaoSelecionada': {
      deep: true,
      handler() {
        const ordenacao = this.pesquisa.ordenacaoSelecionada
        if (ordenacao === 'Nenhuma Selecionada') {
          this.pesquisa.disableCheckBox = true
          this.pesquisa.ocultarOrdenacaoDePesquisa = false
        } else {
          this.pesquisa.disableCheckBox = false
        }
      }
    }
  },
  methods: {
    getPassosImplantacao() {
      this.isLoading = true
      this.$store
        .dispatch(ADMIN_GET_PASSOS_IMPLANTACAO, {
          execute: `${true}`,
          step: this.wizardPath
        })
        .then(data => {
          this.isLoading = false
          if (data && data[0]) {
            this.stepWizard = data[0]
          }
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Falha ao buscar os passos de implantação'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    getDataSupplier() {
      this.isLoading = true
      this.$store
        .dispatch(FORNECEDOR_LOAD)
        .then(data => {
          this.fornecedor = data
          this.getTags()
          this.loadCalendarTimes(this.fornecedor.horarios)
          this.form.fusoHorario =
            this.fornecedor.local_timezone || 'America/Sao_Paulo'
          if (this.finishOrderManually && !this.form.statusAposFinalizacao) {
            this.form.statusAposFinalizacao = ORDER_STATUS.EMENTREGA
          } else if (!this.finishOrderManually) {
            this.form.statusAposFinalizacao = null
          }
          this.pesquisa.ordenacaoSelecionada = this.fornecedor.ordenacao_de_pesquisa
          this.pesquisa.ocultarOrdenacaoDePesquisa = this.fornecedor.ocultar_ordenacao_de_pesquisa
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Não foi possível buscar informações do forncedor'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    getDataParams() {
      this.isLoading = true
      this.$store
        .dispatch(PARAMETRO_LOAD)
        .then(data => {
          if (data.seo && data.seo.config) {
            this.seo = { slug: this.getFornecedorId, ...data.seo.config }
          }
          this.dominio = _.get(data, 'dominio.config.dominio', '')
          this.dominioAdicionais = _.get(
            data,
            'dominio.config.dominio_adicionais',
            []
          )
          this.endereco = {
            ...this.endereco,
            ..._.get(data, 'endereco.config', {})
          }
          this.configPreco = {
            ...this.configPreco,
            ..._.get(data, 'configPreco.config', {})
          }
          this.configPedido = {
            ...this.configPedido,
            ..._.get(data, 'configPedido.config', {})
          }
          this.configCliente = {
            ...this.configCliente,
            ..._.get(data, 'configCliente.config', {})
          }
          this.usarConfig.minPedidoOrcamento = _.get(
            data,
            'configPedido.config',
            {}
          ).minPedidoOrcamento
            ? true
            : false
          this.configPesquisaEndereco = {
            ...this.configPesquisaEndereco,
            ..._.get(data, 'configPesquisaEndereco.config', {})
          }
          this.configEmbalagem = {
            ...this.configEmbalagem,
            ..._.get(data, 'configEmbalagem.config', {})
          }
          this.oldDomains = [...this.dominioAdicionais, this.dominio]
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response ||
            'Não foi possível buscar as informações de parâmetros'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    getTags() {
      this.isLoading = true
      this.$store
        .dispatch(PARAMETRO_LOAD_TAGS, {
          onlyAdmin: '',
          onlyAdvancedMaintenance: ''
        })
        .then(data => {
          if (data) {
            this.form.tags = this.convertTag(data)
          }
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Não foi possível buscar as tags'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    convertTag(originTags) {
      const newTags = originTags.map(ot => {
        if (ot.onlyAdmin) return ot
        return {
          ...ot,
          active:
            this.moduloFornecedorAtivo(ot.modulos) ||
            this.moduloFornecedorInativo(ot.inativeModulos)
              ? ot.active
              : false
        }
      })
      return newTags
    },
    addDominio() {
      this.dominioAdicionais.push('')
    },
    removerDominio(i) {
      this.dominioAdicionais = this.dominioAdicionais.filter((item, index) => {
        return index != i
      })
    },
    normalizeDataToSubmit() {
      if (this.$refs.areaMap && !this.apenasPortfolioAtivo()) {
        this.$refs.areaMap.updateCoords()
      }
      const params = {
        local_timezone: this.form.fusoHorario,
        ordenacaoDePesquisa: this.pesquisa.ordenacaoSelecionada,
        ocultarOrdenacaoDePesquisa: this.pesquisa.ocultarOrdenacaoDePesquisa
      }
      if (!this.usarConfig.minPedidoOrcamento) {
        this.configPedido.minPedidoOrcamento = null
      }
      const configCliente = {
        limite_credito_disponivel: this.prepareNumberDecimalNotFixed(
          this.configCliente.limite_credito_disponivel
        )
      }
      const {
        maxPedidosClientes,
        minPedidoOrcamento,
        maxPagamentoPedido
      } = this.configPedido
      const configPedido = {
        maxPedidosClientes: this.prepareNumberDecimalNotFixed(
          maxPedidosClientes
        ),
        minPedidoOrcamento: this.prepareNumberDecimalNotFixed(
          minPedidoOrcamento
        ),
        maxPagamentoPedido: this.prepareNumberDecimalNotFixed(
          maxPagamentoPedido
        )
      }
      const configEmbalagem = {
        precoEmbalagem:
          this.configEmbalagem.precoEmbalagem &&
          this.prepareNumberDecimal(this.configEmbalagem.precoEmbalagem)
      }
      return {
        dominio: this.dominio,
        oldDomains: this.oldDomains,
        configPesquisaEndereco: this.configPesquisaEndereco,
        configEmbalagem,
        configPreco: this.configPreco,
        params,
        loja: {
          ...this.coordenadasLoja,
          descricao_endereco_loja: _.get(
            this.endereco,
            'descricao_endereco_loja',
            ''
          )
        },
        seo: this.seo,
        tags: this.form.tags.filter(t => t.active).map(t => t.id),
        dominioAdicionais: this.dominioAdicionais,
        endereco: this.endereco,
        configPedido,
        configCliente,
        statusAposFinalizacao: this.form.statusAposFinalizacao
      }
    },
    prepareNumberDecimal(number) {
      if (!number) return
      return Number(
        Number(number)
          .toFixed(2)
          .replace(',', '.')
          .replace(/\s/g, '')
      ).toFixed(2)
    },
    prepareNumberDecimalNotFixed(number) {
      if (!number) return
      return number
        .toString()
        .replace(',', '.')
        .replace(/\s/g, '')
    },
    alterarAgendaEntrega() {
      let validate = ''
      const data = this.weekdays.map(w => {
        if (w.active && (!w.iHour || !w.fHour)) {
          validate =
            'O horário de atendimento é obrigatório para os dias selecionados'
        }
        return { entrega: w.active, inicio: w.iHour, final: w.fHour }
      })
      if (validate) return this.$vueOnToast.pop('error', validate)
      this.isLoading = true
      this.$store
        .dispatch(PARAMETRO_UPDATE_AGENDA, {
          calendario: data
        })
        .then(() => {
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Falha ao atualizar agenda de entrega'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    onSubmit() {
      this.alterarAgendaEntrega()
      const data = this.normalizeDataToSubmit()
      const validate = this.validateForm(data)
      if (validate) return this.$vueOnToast.pop('error', validate)
      this.isLoading = true
      this.$vueOnToast.pop('warning', 'Salvando...', 1000)
      this.$store
        .dispatch(PARAMETRO_UPDATE_DATA, data)
        .then(data => {
          setTimeout(() => {
            if (this.getFornecedor.publicado) {
              const msg =
                data && data.Mensagem
                  ? data.Mensagem
                  : 'Configurações de parâmetros atualizadas'
              this.$vueOnToast.pop('success', msg)
            } else {
              this.showWizard = 'sucesso'
              this.getPassosImplantacao()
            }
            this.isLoading = false
            this.oldDomains = [...this.dominioAdicionais, this.dominio]
          }, 500)
        })
        .catch(err => {
          const error = err.responseJSON ? err.responseJSON : err
          const msg =
            error && error.Mensagem
              ? error.Mensagem
              : 'Erro ao salvar Configurações do pedido'
          const isSucesso = error && error.Sucesso ? error.Sucesso : false
          isSucesso
            ? this.$vueOnToast.pop('warning', msg)
            : this.$vueOnToast.pop('error', msg)
          this.isLoading = false
        })
    },
    validateForm(form) {
      let msg = ''
      if (
        !this.apenasPortfolioAtivo() &&
        isNaN(form.configCliente.limite_credito_disponivel)
      ) {
        msg =
          'O valor do limite de crédito é inválido (Configuração de cliente).'
      }
      if (isNaN(form.configPedido.maxPedidosClientes)) {
        msg =
          'O valor do máximo de pedidos por cliente é inválido (Configuração de pedido).'
      }
      if (
        form.configPedido.minPedidoOrcamento &&
        isNaN(form.configPedido.minPedidoOrcamento)
      ) {
        msg =
          'O valor minimo para pedidos serem criados como orçamento é inválido (Configuração de pedido).'
      }
      if (isNaN(form.configPedido.maxPagamentoPedido)) {
        msg =
          'O número máximo de tentativas de pagamentos por pedido é inválido (Configuração de pedido).'
      }
      return msg
    },
    apenasPortfolioAtivo() {
      return (
        this.moduloFornecedorAtivo([ModulesEnum.PORTFOLIO_WEB]) === true &&
        this.moduloFornecedorAtivo(this.modulosForm) === false
      )
    },
    onLoadMap(deliveryArea) {
      this.endereco = {
        ...this.endereco,
        descricao_endereco_loja: _.get(deliveryArea, 'descricao_endereco_loja')
      }
    },
    onReceiveCoords(coords) {
      this.coordenadasLoja = coords
    },
    loadCalendarTimes(horarios) {
      if (horarios) {
        horarios.forEach(h => {
          this.weekdays[h.dia].active = h.efetuaentregas
          this.weekdays[h.dia].iHour = h.horainicio
          this.weekdays[h.dia].fHour = h.horafim
        })
      }
    },
    previewPrecoArredondado(index) {
      let preco = `${this.precoPreview[index] || ''}`.trim()
      preco = preco.includes(',')
        ? preco.replace('.', '').replace(',', '.')
        : preco
      return this.getPrecoArredondado(
        preco,
        this.configPreco.decimais,
        this.configPreco.arredondamento
      )
    },
    getPrecoArredondado(preco = 0) {
      const formatPrice = (price, decimals = 6, roundingMode) => {
        const _price = new BigNumber(price)
        return Number(_price.toFixed(Number(decimals), BigNumber[roundingMode]))
      }
      const _preco = formatPrice(
        preco,
        this.configPreco.decimais,
        this.configPreco.arredondamento
      )
      return {
        value: _preco,
        isLower: _preco < preco,
        isGreater: _preco > preco
      }
    },
    normalizeSlug() {
      this.seo.slug = StringUtils.normalizeUrl(this.seo.slug)
    }
  },
  async created() {
    await this.getDataSupplier()
    await this.getDataParams()
  }
}
</script>
