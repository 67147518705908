<template>
  <v-alert dense outlined type="error" class="text-start" v-if="uso">
    Já existe uma loja no superon que utiliza este mesmo domínio, verifique e
    tente novamente.
  </v-alert>
  <v-alert dense outlined type="error" class="text-start" v-else-if="falha">
    A validação do domínio foi inconclusiva, verifique se o domínio foi digitado
    corretamente.
  </v-alert>
  <span v-else-if="validacao.length > 0">
    <v-alert
      dense
      outlined
      :type="v.status == 'valid' ? 'success' : 'error'"
      class="text-start"
      v-for="(v, i) in validacao"
      :key="i"
    >
      <span v-if="v.status == 'valid'">
        O domínio está apontando para os nossos servidores corretamente
      </span>
      <span v-if="v.status == 'invalid'">
        O domínio não está apontando para nenhum de nossos servidores
      </span>
      <span v-if="v.status == 'warn'">
        Alguns apontamentos do domínio não está apontando para nossos servidores
      </span>
    </v-alert>
    <v-simple-table class="px-5 pt-2 pb-10">
      <thead>
        <tr>
          <th>Servidor</th>
          <th>Tipo</th>
          <th>Valor</th>
          <th>Situação</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="validacao in listValidation" :key="validacao.id">
          <td>{{ validacao.server }}</td>
          <td>{{ validacao.type }}</td>
          <td>{{ validacao.address }}</td>
          <td>
            <i class="fas fa-check" v-if="validacao.valid"></i>
            <i class="fas fa-times" v-else></i>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </span>
</template>

<script>
import { PARAMETRO_DOMAIN_VALIDATE } from '@/store/actions/parametros'

export default {
  name: 'DomainMessage',
  props: {
    dominio: {
      type: String,
      default: '',
      required: false
    }
  },
  data: () => ({
    uso: false,
    falha: false,
    validacao: []
  }),
  methods: {
    domainValidate(dominio) {
      this.$store
        .dispatch(PARAMETRO_DOMAIN_VALIDATE, dominio)
        .then(data => {
          if (data && data.validacao.length > 0) {
            this.validacao = data.validacao
          } else if (data && data.uso) {
            this.uso = data.uso
          } else {
            this.falha = true
          }
        })
        .catch(() => {
          this.falha = true
        })
    }
  },
  computed: {
    listValidation() {
      return this.validacao.reduce((validations, server) => {
        const serverValidation = server.validation.map(item => ({
          id: `${server.name}-${item.address}-${item.type}`,
          address: item.address,
          type: item.type,
          valid: item.valid,
          server: server.name
        }))
        return [...validations, ...serverValidation]
      }, [])
    }
  },
  watch: {
    value(newValue) {
      this.domainValidate(newValue)
    }
  },
  created() {
    this.domainValidate(this.dominio)
  }
}
</script>
