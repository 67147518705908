<template>
  <div>
    <div id="map"></div>
    <FormButton
      class="btn-delete-layer"
      :rounded="false"
      type="light"
      @click="clearLayers(true)"
    >
      <v-icon size="18">fa-trash-alt</v-icon>
    </FormButton>
  </div>
</template>

<script>
import FormButton from '@/components/form/FormButton'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import icon from 'leaflet/dist/images/marker-icon.png'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import 'leaflet-draw'
import { PARAMETRO_LOAD_DELIVERY_AREA } from '@/store/actions/parametros'

export default {
  name: 'ServiceAreaMap',
  components: {
    FormButton
  },
  data() {
    return {
      map: null,
      currentPolygon: null,
      currentMarker: null,
      editableLayers: null,
      drawControl: null,
      drawEditToolbar: null,
      deliveryArea: {
        idareaatuacao: '',
        descricao_endereco_loja: null
      }
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    markerIcon() {
      return L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconRetinaUrl: icon,
        iconSize: [30, 41],
        iconAnchor: [15, 41],
        popupAnchor: [-1, -35]
      })
    }
  },
  methods: {
    getData() {
      this.$store
        .dispatch(PARAMETRO_LOAD_DELIVERY_AREA)
        .then(result => {
          let coords = null
          let storeCoords = null
          if (result[0]) {
            coords = result[0].coordenadas
            const data =
              result[0].coordenadas_lojas && result[0].coordenadas_lojas.default
                ? result[0].coordenadas_lojas.default
                : null
            storeCoords =
              data && data.latitude ? [data.latitude, data.longitude] : null
            this.deliveryArea = result[0]
            this.$emit('load', this.deliveryArea)
          }
          this.initMap()
          this.initDraw(coords, storeCoords)
        })
        .catch(err => {
          const erro =
            err.response || 'Não foi possível buscar as coordenadas do mapa'
          this.$vueOnToast.pop('error', erro)
        })
    },
    initMap() {
      const southWest = L.latLng(-62.69179, -16.958463)
      const northEast = L.latLng(18.443136, -96.728259)
      const mybounds = L.latLngBounds(southWest, northEast)
      const lat = -15.77972
      const lng = -47.92972
      const zoom = 4
      if (document.getElementById('map')) {
        this.map = new L.Map('map', {
          center: { lat, lng },
          zoom: zoom,
          minZoom: 4,
          maxBounds: mybounds
        })
        L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(this.map)
      }
    },
    initDraw(coords, storeCoords) {
      this.translateLeafletDraw()
      let polygon = coords ? L.polygon(coords) : null
      let marker = storeCoords ? L.marker(storeCoords) : null
      const hasPolygon = polygon && coords && coords.length
      const showCreatePolygonButton = hasPolygon ? false : true
      this.editableLayers = new L.FeatureGroup()
      this.map.addLayer(this.editableLayers)
      this.drawControl = new L.Control.Draw(
        this.getDrawOptions(
          showCreatePolygonButton,
          !showCreatePolygonButton,
          true
        )
      )
      this.map.addControl(this.drawControl)
      this.setDrawEvents()
      if (hasPolygon) {
        this.setLayers(marker, polygon)
      }
      if (showCreatePolygonButton) {
        this.clearLayers(false)
      }
    },
    translateLeafletDraw() {
      L.Marker.prototype.options.icon = this.markerIcon
      L.drawLocal.draw.toolbar.buttons.polygon = 'Desenhar area de venda'
      L.drawLocal.draw.toolbar.buttons.marker = 'Desenhar um marcador'
      L.drawLocal.draw.toolbar.actions = {
        save: {
          text: 'Salvar',
          title: 'Salvar desenho'
        },
        cancel: {
          text: 'Cancelar',
          title: 'Cancelar desenho'
        },
        text: 'Cancelar',
        title: 'Cancelar desenho'
      }
      L.drawLocal.draw.toolbar.finish = {
        text: 'Finalizar',
        title: 'Finalizar desenho'
      }
      L.drawLocal.draw.toolbar.undo = {
        text: 'Apagar ultimo ponto',
        title: 'Apagar ultimo ponto criado'
      }
      L.drawLocal.draw.handlers.marker.tooltip = {
        start: 'Click para colocar o marcador'
      }
      L.drawLocal.draw.handlers.polygon.tooltip = {
        cont: 'Click  para continuar desenhando a forma',
        end: 'Click no primeiro ponto para fechar a forma',
        start: 'Click para começar a desenhar a forma'
      }
      L.drawLocal.edit.handlers = {
        edit: {
          tooltip: {
            subtext: 'Click para desfazer as mudanças.',
            text: 'Mova os pontos ou marcadores para editar a forma'
          }
        },
        remove: {
          tooltip: {
            text: 'Click para remover.'
          }
        }
      }
      L.drawLocal.edit.toolbar = {
        actions: {
          cancel: {
            text: 'Cancelar',
            title: 'Cancelar edição, descartar todas alterações'
          },
          clearAll: {
            text: 'Limpar tudo',
            title: 'Limpar todas camadas'
          },
          save: {
            text: 'Salvar',
            title: 'Salvar mudanças'
          }
        },
        buttons: {
          edit: 'Editar camadas',
          editDisabled: 'Nenhuma camada para edição',
          remove: 'Deletar camadas',
          removeDisabled: 'Nenhuma camada para remoção'
        }
      }
    },
    getDrawOptions(showPolygonButton, showEditButton) {
      const polygonOptions = {
        allowIntersection: false,
        drawError: {
          color: '#e1e100',
          message: '<strong>Opa!<strong> você não pode desenhar isso!!'
        }
      }
      const editOptions = {
        featureGroup: this.editableLayers,
        remove: false
      }
      return {
        position: 'topleft',
        draw: {
          circle: false,
          marker: {
            icon: this.markerIcon
          },
          circlemarker: false,
          polyline: false,
          rectangle: false,
          polygon: showPolygonButton ? polygonOptions : false
        },
        edit: showEditButton ? editOptions : false
      }
    },
    setLayers(marker, polygon) {
      if (marker) {
        this.currentMarker = marker
        this.editableLayers.addLayer(marker)
      }
      if (polygon) {
        this.currentPolygon = polygon
        this.editableLayers.addLayer(polygon)
      }
    },
    setDrawEvents() {
      this.map.on(L.Draw.Event.CREATED, ({ layer, layerType }) => {
        this.processLayer(layer, layerType, true)
      })
      this.map.on(L.Draw.Event.EDITED, ({ layers }) => {
        layers.eachLayer(layer => {
          let type = this.getShapeType(layer)
          this.processLayer(layer, type, false)
        })
      })
    },
    processLayer(layer, type, doRemoveLayer) {
      switch (type) {
        case 'polygon':
          this.currentPolygon = layer
          this.doProcessLayer(layer, false, doRemoveLayer)
          break
        case 'marker':
          this.currentMarker = layer
          this.doProcessLayer(layer, true, doRemoveLayer)
          break
      }
    },
    getShapeType(layer) {
      if (layer instanceof L.Circle) {
        return 'circle'
      }
      if (layer instanceof L.Marker) {
        return 'marker'
      }
      if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
        return 'polyline'
      }
      if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
        return 'polygon'
      }
      if (layer instanceof L.Rectangle) {
        return 'rectangle'
      }
    },
    doProcessLayer(layer, isMarker, doRemoveLayer) {
      if (doRemoveLayer) {
        this.editableLayers.eachLayer(l => {
          if (isMarker) {
            if (l !== this.currentPolygon) this.editableLayers.removeLayer(l)
          } else {
            if (l !== this.currentMarker) this.editableLayers.removeLayer(l)
          }
        })
      }
      if (isMarker) this.setDeliveryAreaDescription(layer)
      if (!isMarker) this.removeDrawControl(false)
      this.editableLayers.addLayer(layer)
    },
    setDeliveryAreaDescription(layer) {
      this.$gmapApiPromiseLazy().then(() => {
        const geocoder = new google.maps.Geocoder()
        const latlng = { lat: layer._latlng.lat, lng: layer._latlng.lng }
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === 'OK' && results[0]) {
            this.deliveryArea.descricao_endereco_loja =
              results[0].formatted_address
          }
        })
      })
    },
    removeDrawControl(showPolygonButton) {
      this.map.removeControl(this.drawControl)
      this.drawControl = new L.Control.Draw(
        this.getDrawOptions(showPolygonButton, !showPolygonButton)
      )
      this.map.addControl(this.drawControl)
    },
    updateCoords() {
      let data = null
      if (this.map) {
        let areavenda = []
        if (this.currentPolygon && this.currentPolygon._latlngs) {
          this.currentPolygon._latlngs[0].forEach(latLng => {
            areavenda.push({ lat: latLng.lat, lng: latLng.lng })
          })
        } else {
          areavenda = this.deliveryArea.coordenadas.map(latLng => {
            return { lat: latLng[0], lng: latLng[1] }
          })
        }
        const coordenadas_lojas =
          this.currentMarker && this.currentMarker._latlng
            ? {
                default: {
                  latitude: this.currentMarker._latlng.lat,
                  longitude: this.currentMarker._latlng.lng
                }
              }
            : this.deliveryArea.coordenadas_lojas
        data = {
          idareaatuacao: this.deliveryArea.idareaatuacao || '',
          coordenadas: areavenda,
          coordenadas_lojas: coordenadas_lojas
        }
      } else {
        data = {
          idareaatuacao: null,
          coordenadas: null,
          coordenadas_lojas: null
        }
      }
      this.$emit('receive-coords', data)
    },
    clearLayers(showPrompt) {
      const clear = () => {
        this.editableLayers.clearLayers()
        this.removeDrawControl(true)
      }
      if (!showPrompt) {
        clear()
        return
      }
      const options = {
        text:
          'Caso não seja definida uma nova marcação, prevalecerá a marcação definida anteriormente.',
        title: `Deseja realmente excluir a marcação no mapa atual?`
      }
      this.confirmSwal(options, () => {
        try {
          clear()
        } catch (error) {
          this.$vueOnToast.pop(
            'error',
            'Saia do modo de edição antes de realizar essa ação!'
          )
        }
      })
    }
  }
}
</script>

<style>
#map {
  height: 60vh;
}

.btn-delete-layer {
  position: absolute;
  border-radius: 10px;
  top: 100px;
  right: 35px;
  z-index: 400;
}

.leaflet-draw-actions a {
  background-color: white !important;
  color: #000;
  font-size: 1em;
}
</style>
